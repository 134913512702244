<template>
  <div class="page-container">
    <Table ref="tableRef" add :params="params" :request="searchData" @add="addNew" tooltip-effect="light">
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="userLabel" label="用户标签">
        <template #default="{row}">
          {{ row.userLabel == 1 ? '内部用户' : '普通用户' }}
        </template>
      </el-table-column>
      <el-table-column prop="userPhone" label="手机号" />
      <el-table-column prop="content" label="内容">
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
          >
            <template #default>
              {{row.content}}
            </template>
            <template #reference>
              <span class="multi-line-text">{{row.content}}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="externalLink" label="公众号链接" />
      <el-table-column prop="likeCount" label="点赞" width="100" sortable="custom" />
      <el-table-column prop="collectionCount" label="收藏" width="100" sortable="custom"/>
      <el-table-column prop="commentCount" label="评论" width="100" sortable="custom">
        <template #default="{row}">
          <el-button @click="checkDetail(row)" type="text" size="small">{{row.commentCount}}</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="标签" width="150" >
        <template #default="{row}">
          <span v-if="row.hot">热门贴</span>
          <span v-if="row.top">置顶贴</span>
          <el-button v-if="row.hot" type="default" size="small">热门贴</el-button>
          <el-button v-if="row.top" type="default" size="small">置顶贴</el-button>
        </template>
      </el-table-column> -->
      <el-table-column prop="postType" label="类型" width="100" />
      <el-table-column prop="time" label="时间" width="150" sortable="custom" />
      <!-- <el-table-column prop="postStatus" label="状态" width="100" >
        <template #default="{row}">
          {{ row.postStatus == 1 ? '显示':'隐藏' }}
        </template>
      </el-table-column> -->
      <el-table-column label="操作"  width="200">
        <template #default="{row}">
          <el-button v-if="!row.top" type="primary" plain size="mini" @click="setTop(row)">置顶</el-button>
          <el-button v-else plain size="mini" @click="cancelTop(row)">取消置顶</el-button>
          <el-button v-if="row.postStatus == 1" type="danger" plain size="mini" @click="hideDetail(row)">隐藏</el-button>
          <el-button v-if="row.postStatus == 100" type="primary" plain size="mini" @click="showDetail(row)">显示</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="新建帖子" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="标题：" prop="title">
          <el-input v-model="detailform.title" placeholder="标题" clearable />
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="内容"
            clearable
            maxlength="500"
            show-word-limit
            v-model="detailform.content"
          />
        </el-form-item>
        <el-form-item label="图片：">
          <Upload
            v-model="detailform.picture"
            multiple
            :limit="3"
            list-type="picture-card"
            type="image"
            :auto-upload="true"
          >
            <i class="el-icon-plus"></i>
          </Upload>
        </el-form-item>
        <el-form-item label="视频：">
          <Upload
            v-model="detailform.video"
            :limit="1"
            :auto-upload="true"
          >
            <el-button type="text">点击上传视频</el-button>
            <template #tip>
              <div class="el-upload__tip" style="color: red">
                只能上传一个格式为mp4的视频。
              </div>
            </template>
          </Upload>
        </el-form-item>
        <el-form-item label="公众号链接：" prop="externalLink">
          <el-input v-model="detailform.externalLink" placeholder="公众号链接" clearable />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="formSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDataVisible" top="5vh" width="60%" title="帖子详情" destroy-on-close :modal="true" :append-to-body="true">
      <el-form class="dialog-form" label-width="120px">
        <el-form-item class="list-item" label="时间：">
          <div>{{ detailData.time }}</div>
        </el-form-item>
        <el-form-item class="list-item" label="姓名：">
          <div>{{ detailData.userName }}</div>
        </el-form-item>
        <el-form-item class="list-item" label="标题：">
          <div>{{ detailData.title }}</div>
        </el-form-item>
        <el-form-item class="list-item" label="内容：">
          <div class="form-textarea">
            {{ detailData.content }}
          </div>
        </el-form-item>
        <el-form-item class="list-item" label="图片/视频：">
          <template
            v-for="(item, index) in getMedia(detailData.attachments, 0)"
            :key="index"
          >
            <el-image
              v-if="item"
              class="el-image-inline"
              :src="item"
              :preview-src-list="getMedia(detailData.attachments, 0)"
            >
            </el-image>
          </template>
          <video v-if="getMedia(detailData.attachments, 1)" class="el-video-inline" controls>
            <source :src="getMedia(detailData.attachments, 1)" />
          </video>
        </el-form-item>
        <el-form-item class="list-item" label="评论：">
          <el-card class="box-card" v-for="comment in detailData.commentlist" :key="comment.id">
            <template #header>
              <div class="card-header">
                <div>
                  <span style="margin-right:20px;">{{comment.userName}}</span> 
                  <span>{{comment.time}}</span>
                </div>
                <el-button class="button" type="text" @click="deletComment(comment)">删除</el-button>
              </div>
            </template>
            <div>{{comment.content}}</div>
            <div v-for="sub in comment.subCommentList" :key="sub.id" class="card-item">
              <label >{{sub.userName}}：</label>
              <label v-if="sub.ref.id != comment.id" style="color:#404DFA;">回复@{{sub.ref.userName}}：</label>
              <label >{{sub.content}}</label>
            </div>
            <div style="text-align:center;" v-if="(comment.subCommentList||[]).length < comment.commentCount">
              <el-button @click="getMoreSubComment(comment)" type="text" size="small">加载更多</el-button>
            </div>
          </el-card>
          <div style="text-align:center;" v-if="detailData.commentlist.length < commentParam.total">
            <el-button @click="getMoreComment" type="text" size="small">加载更多</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "vue";
// import { watch } from 'vue'
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/elp/table";
import { urlMap } from '@/common/config';
import Upload from '@/components/upload'

export default {
  components: {
    Table,
    Upload
  },
  mixins: [common],
  setup() {
    const tags = [{
      id: 'hot',
      name: "热门",
    },
    {
      id: 'top',
      name: "置顶",
    },
    {
      id: 'case',
      name: "应用案例",
    }]
    const statusList = [
      { name: '显示', value: 1 },
      { name: '隐藏', value: 100 },
      { name: '全部', value: '' },
    ]
    const state = reactive({
      tableRef: null,
      dialogFormVisible: false,
      dialogDataVisible: false,
      detailformRef: null,
      detailform: {
        title: '',
        content: '',
        attachments: '',
        picture: '',
        video: '',
        externalLink: '', // 公众号链接
      },
      detailData: {},
      params: [
        {
          key: "userName",
          value: "",
          label: "姓名",
        },
        {
          key: "userPhone",
          value: "",
          label: "手机号",
        },
        {
          key: "title",
          value: "",
          label: "标题",
        },
        {
          type: "Select",
          key: "tag",
          value: "",
          label: "标签",
          options: {
            data: tags,
            label: "name",
            value: "id"
          }
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
        {
          type: "Select",
          key: "postStatus",
          value: 1,
          label: "帖子状态",
          options: {
            data: statusList,
            label: "name",
            value: "value"
          }
        },
      ],
      commentParam: { // 评论列表参数
        postId: 0,
        page: 1,
        size: 10,
        total: 0
      },
      upload: null,
      imglist: [],
      uploadFile: urlMap['uploadFile']
    })
    const detailformRules = reactive({
      title: {
        required: true,
        message: '请输入帖子标题',
        trigger: 'blur',
      },
      content: {
        required: true,
        message: '请输入帖子内容',
        trigger: 'blur',
      }
    })
    // watch(() => state.query,
    //   (cur, pre) => {
    //     console.log("新值:", cur, "老值:", pre);
    //     if (state.query.tag) {
    //       state.query[state.query.tag] = true
    //     }
    //   },
    //   { immediate: true, deep: true }
    // )
    const getData = (params) => {
      return request("getForumList", params)
    };
    const getTop = (params) => {
      return request("getTopList", params)
    };
    const getHot = (params) => {
      return request("getForumList", {
        ...params,
        hot: true
      });
    };
    const getCase = async (params) => {
      return request("getApplicationList", params);
    };
    const searchData = async (params) => {
      const query = { ...params }
      if (params.dateRange?.length) {
        query.leftTime = params.dateRange[0]
        query.rightTime = params.dateRange[1]
      } else {
        query.leftTime = undefined
        query.rightTime = undefined
      }
      if (query.tag == 'top') {
        return getTop(query)
      } else if (query.tag == 'hot') {
        return getHot(query)
      } else if (query.tag == 'case') {
        return getCase(query)
      } else {
        return getData(query)
      }
    };
    const addNew = () => {
      state.dialogFormVisible = true
      state.detailform = {
        title: '',
        content: '',
        attachments: '',
        picture: '',
        video: '',
      }
    }
    const formSubmit = async () => {
      try {
        state.detailformRef && await state.detailformRef.validate()
        await request("addForum", {
          title: state.detailform.title,
          content: state.detailform.content,
          attachments: state.detailform.picture + ';;' + state.detailform.video,
          externalLink: state.detailform.externalLink
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "发布成功!"
        })
        state.dialogFormVisible = false
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    const checkDetail = async (row) => {
      console.log('checkDetail', row)
      try {
        state.commentParam = { // 评论列表参数
          postId: row.id,
          page: 1,
          size: 10,
          total: 0
        }
        const res = await request("commentlist", state.commentParam)
        state.commentParam.page++
        row.commentlist = res.records
        state.commentParam.total = res.total;
        state.detailData= row
        state.dialogDataVisible=true
      } catch (error) {
        console.error(error)
      }
    };
    const getMedia = (attachments, type=0) => {
      if (type == 0) {
        let images = (attachments||'').split(';;')[0]
        let imglist = images ? images.split(',') : []
        return imglist
      } else {
        return(attachments||'').split(';;')[1] || ''
      }
    }
    const getMoreComment = async () => {
      try {
        const res = await request("commentlist", state.commentParam)
        state.detailData.commentlist.push(...res.records)
        state.commentParam.total = res.total;
        state.commentParam.page++
      } catch (error) {
        console.error(error)
      }
    }
    const setTop = async (row) => {
      try {
        await request('setTop', {
          id: row.id
        })
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: "success",
          message: "置顶成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const cancelTop = async (row) => {
      try {
        await request('cancelTop', {
          id: row.id
        })
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: "success",
          message: "取消成功!"
        })

      } catch (error) {
        console.error(error)
      }
    }
    const hideDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定隐藏？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request('setPostStatus', {
          id: row.id,
          postStatus: 100
        })
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const showDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定显示？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request('setPostStatus', {
          id: row.id,
          postStatus: 1
        })
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const getMoreSubComment = async (row) => {
      try {
        const res = await request("subCommentList", {
          postId: state.detailData.id,
          commentId: row.id,
          page: row.page || 1,
          size: 10,
        })
        if (!row.page) {
          row.page=1
          row.subCommentList = res.records
        } else {
          row.subCommentList.push(...res.records)
        }
        row.page++
      } catch (error) {
        console.error(error)
      }
    }
    const deletComment = async (comment={}) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deleteComment", {
          id: comment.id
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
        checkDetail(state.detailData)
      } catch (error) {
        console.error(error)
      }
    }
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      addNew,
      formSubmit,
      checkDetail,
      getMedia,
      setTop,
      cancelTop,
      hideDetail,
      showDetail,
      deletComment,
      getMoreComment,
      getMoreSubComment
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
}
.box-card {
  margin-bottom: 10px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multi-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
