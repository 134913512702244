<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="createTime" label="创建时间" width="150" />
      <el-table-column prop="robotId" label="机器人编码" width="150" />
      <el-table-column prop="cabinetId" label="控制器编码" width="150" />
      <el-table-column prop="companyName" label="公司名称" min-width="200" />
      <el-table-column
        prop="companyAddress"
        label="公司地址"
        min-width="200"
      />
      <el-table-column prop="name" label="联系人" width="150" />
      <el-table-column prop="phoneNumber" label="联系电话" width="150" />
      <el-table-column
        prop="email"
        label="邮箱"
        min-width="150"
      />
      <el-table-column
        prop="crmWorkOrderId"
        label="CRM工单编号"
        min-width="150"
      />
      <el-table-column label="操作" fixed="right" width="100">
        <template #default="scope">
          <el-button v-if="!scope.row.crmWorkOrderId" type="primary" plain size="mini"  @click="checkDetail(scope.row)"
            >工单处理</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
  <el-dialog v-model="dialogFormVisible" top="5vh" title="工单详情" destroy-on-close :modal="true" :append-to-body="true">
    <el-form ref="form" class="dialog-form" :model="form" label-width="120px">
      <el-form-item label="机器人编码：">
        <div>{{ form.robotId }}</div>
      </el-form-item>
      <el-form-item label="控制器编码：">
        <div>{{ form.cabinetId }}</div>
      </el-form-item>
      <el-form-item label="公司名称：">
        <div>{{ form.companyName }}</div>
      </el-form-item>
      <el-form-item label="公司地址：">
        <div>{{ form.companyAddress }}</div>
      </el-form-item>
      <el-form-item label="联系人：">
        <div>{{ form.name }}</div>
      </el-form-item>
      <el-form-item label="联系电话：">
        <div>{{ form.phoneNumber }}</div>
      </el-form-item>
      <el-form-item label="联系邮箱：">
        <div>{{ form.email }}</div>
      </el-form-item>
      <el-form-item label="故障详情：">
        <div class="form-textarea">
          <div>电控柜版本：{{ form.cabinetVersion }}</div>
          <div>SCB版本：{{ form.scbVersion }}</div>
          <div>APP版本：{{ form.appVersion }}</div>
          <div>SDK版本：{{ form.sdkVersion }}</div>
          <div>首次使用：{{ form.isFirstTimeUse ? "是" : "否" }}</div>
          <div>详情描述：{{ form.errorDescription }}</div>
        </div>
      </el-form-item>
      <el-form-item label="故障附件：">
        <template
          v-for="(item, index) in (form.errorPictures || '').split(',')"
          :key="index"
        >
          <el-image
            v-if="item"
            class="el-image-inline"
            :src="item"
            :preview-src-list="(form.errorPictures || '').split(',')"
          >
          </el-image>
        </template>
        <template
          v-for="(item, index) in (form.errorVideos || '').split(',')"
          :key="index"
        >
          <video v-if="item" class="el-image-inline" controls>
            <source :src="item" />
          </video>
        </template>
      </el-form-item>
      <el-form-item label="CRM工单编号：">
        <el-input v-model="form.crmWorkOrderId" placeholder="CRM工单编号" clearable />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" type="primary" @click="onSubmit">处理工单</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
// import { ElMessageBox } from 'element-plus';
import { ElMessage } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data() {
    return {
      dialogFormVisible: false,
      form: {
        robotId: "",
        cabinetId: "",
        cabinetVersion: "",
        scbVersion: "",
        sdkVersion: "",
        appVersion: "",
        isFirstTimeUse: "",
        errorDescription: "",
        errorPictures: "",
        errorVideos: "",
        name: "",
        phoneNumber: "",
        email: "",
        companyName: "",
        companyAddress: "",
        crmWorkOrderId: "",
      },
      params: [{
        key: 'robotId',
        value: '',
        label: '机器人编码'
      },{
        key: 'cabinetId',
        value: '',
        label: '控制柜编码'
      },{
        key: 'companyName',
        value: '',
        label: '公司名称'
      }],
      query: {
        robotId: "", // 机器人ID
        cabinetId: "", // 控制器ID
        companyName: "", // 公司名称
      },
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      robotStatusList: [], // 机器人状态下拉列表
      industryList: [], // 行业下拉列表
    };
  },
  watch: {
    "query.province": function(value, old) {
      if (value && value !== old) {
        console.log("province", value);
        request("getArea", {
          parentId: Number(value),
        }).then((data) => {
          this.cityList = data;
        });
      }
    },
    "query.city": function(value, old) {
      if (value && value !== old) {
        request("getArea", {
          parentId: Number(value),
        }).then((data) => {
          this.areaList = data;
        });
      }
    },
  },
  created() {
    window.$this = this;
    this.getData();
    // this.getDataMap()
  },
  mounted() {
    // this.tableDataList = robotList({}).then(data => {
    //   this.tableDataList = data
    // })
    // this.$store.dispatch('status/robot', {})
    // .then(data => {
    //   console.log(data)
    // })
    // robotList({}).then(data => {
    //   this.tableDataList = data
    // })
  },
  methods: {
    getData() {
      request("getWorkOrderList", {
        ...this.query,
        page: this.page,
        size: this.size,
      })
        .then((data) => {
          this.tableDataList = data.records;
          this.total = data.total;
          // this.tableDataList = [].concat(data.records);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataMap() {
      try {
        request("getArea", {
          parentId: 1,
        }).then((data) => {
          this.provinceList = data;
        });
        request("getRobotStatusMap").then((data) => {
          this.robotStatusList = data;
        });
        request("getIndustryList").then((data) => {
          this.industryList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    searchData(data) {
      this.query = data
      this.getData()
    },
    updatePage(page, size) {
        this.page = page || 1
        this.size = size || 10
        this.getData()
    },
    async updateWorkOrder() {
      try {
        await request("updateWorkOrder", {
          id: this.form.id,
          crmWorkOrderId: this.form.crmWorkOrderId,
        });
        ElMessage({
          showClose: true,
          type: "success",
          message: "处理成功!",
        });
        this.getData();
      } catch (error) {
        console.error(error);
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      if (val === this.size) return;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      if (!val) return;
      this.page = val;
      this.getData();
    },
    checkDetail(data) {
      this.form = data;
      this.dialogFormVisible = true;
    },
    onSubmit() {
      if (!this.form.crmWorkOrderId) {
        this.$alert("请填写工单编号", {
          type: "warning",
          confirmButtonText: "确定",
        });
        return;
      }
      this.updateWorkOrder();
      this.dialogFormVisible = false;
    },
    dateChange() {
      console.log("dateChange: ", this.dateRange);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>