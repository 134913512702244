<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button type="primary" plain @click="exportData">表格导出</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" tooltip-effect="light">
      <el-table-column prop="createTime" label="创建时间" >
        <template #default="{row}">
          {{formatDate(row.createTime)}}
        </template>
      </el-table-column>
      <el-table-column prop="robotModel" label="型号" />
      <el-table-column prop="robotModelType" label="版本号" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="phoneNumber" label="电话" >
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="industry" label="行业" />
      <el-table-column prop="code" label="体验码" />
      <el-table-column prop="channel" label="渠道" />
      <!-- <el-table-column label="操作"  width="150">
        <template #default="scope">
          <el-button @click="checkDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
        </template>
      </el-table-column> -->
    </Table>
    <el-dialog title="意见反馈详情" v-model="dialogFormVisible" top="5vh" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="form" class="dialog-form" :model="detailform" label-width="120px">
        <el-form-item label="创建时间：">
          <div>{{ detailform.createTime }}</div>
        </el-form-item>
        <el-form-item label="联系人：">
          <div>{{ detailform.userName }}</div>
        </el-form-item>
        <el-form-item label="联系电话：">
          <div>{{ detailform.phoneNumber }}</div>
        </el-form-item>
        <el-form-item label="故障详情：">
          <div class="form-textarea">
            {{ detailform.message }}
          </div>
        </el-form-item>
        <el-form-item label="故障附件：">
          <template
            v-for="(item, index) in (detailform.attachments || '').split(',')"
            :key="index"
          >
            <el-image
              v-if="item"
              class="el-image-inline"
              :src="item"
              :preview-src-list="(detailform.attachments || '').split(',')"
            >
            </el-image>
          </template>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
// import { ElMessage } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
// import { formatTime } from "../../common/utils";


export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      detailform: {
        robotModel: '',
        userName: '',
        email: '',
        phoneNumber: '',
        company: '',
        industry: '',
      },
      params: [
        {
          key: "userName",
          value: "",
          label: "姓名",
        },
        {
          key: "phoneNumber",
          value: "",
          label: "电话",
        },
        {
          type: "Date",
          key: "startTime",
          value: '',
          label: "开始日期",
          options: {
            "type": "date",
            "format": "YYYY-MM-DD",
            "value-format": "x",
            "placeholder": "开始日期",
          }
        },
        {
          type: "Date",
          key: "endTime",
          value: '',
          label: "结束日期",
          options: {
            "type": "date",
            "format": "YYYY-MM-DD",
            "value-format": "x",
            "placeholder": "结束日期",
          }
        },
      ]
    })

    const getData = async () => {
      try {
        const res = await request('getVirtualApplyList', {
          ...state.query,
          pageNumber: state.page,
          pageSize: state.size,
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const exportData = async () => {
      try {
        // window.open(`https://ota.jaka.com/virtual/excel?startTime=${1648694383968}&endTime=`)
        const res = await downloadFile("exportVirtualApplyList", {}, {
          method: 'GET',
          params: state.query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '虚拟体验申请列表.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      state.query = data;
      getData()
    };
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    };
    const checkDetail = (row) => {
      state.dialogFormVisible=true
      state.detailform= row
    };
    const formatNumber = n => {
      n = n.toString()
      return n[1] ? n : `0${n}`
    }
    const formatDate = (time) => {
      const date = new Date(time)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const h = date.getHours()
      const m = date.getMinutes()
      const s = date.getSeconds()

      return `${[year, month, day].map(formatNumber).join('-')} ${[h, m, s].map(formatNumber).join(':')}`

    }
    getData();
    return {
      ...toRefs(state),
      getData,
      exportData,
      searchData,
      updatePage,
      checkDetail,
      formatDate
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>