<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="data.total" @update="updatePage">
      <el-table-column prop="createTime" label="申请时间" />
      <el-table-column prop="robotId" label="机器人编码" />
      <el-table-column prop="cabinetId" label="控制柜编码" />
      <el-table-column prop="cabinetVersion" label="控制柜版本" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="phoneNumber" label="电话" />
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="finished" label="状态" >
        <template #default="{row}">{{ row.finished ? '已处理':'待处理' }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template #default="{row}">
          <el-button
            v-if="!row.finished"
            type="primary" plain size="mini"
            @click="handleDetail(row)"
            >处理</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { ref, reactive } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  setup() {
    const params = [{
      key: 'robotId',
      value: '',
      label: '机器人编码'
    },{
      key: 'cabinetId',
      value: '',
      label: '控制柜编码'
    },{
      key: 'cabinetVersion',
      value: '',
      label: '控制柜版本'
    },{
      key: 'userName',
      value: '',
      label: '姓名'
    },{
      key: 'phoneNumber',
      value: '',
      label: '电话'
    },{
      key: 'company',
      value: '',
      label: '公司'
    },{
      type: 'select',
      key: 'finished',
      value: '',
      label: '状态',
      options: {
        data: [{ name: '已处理', value: true }, { name: '待处理', value: false }],
        label: 'name',
        value: 'value',
      },
    }]
    var query = {
      robotId: "", // 机器人ID
      cabinetId: "", // 控制器ID
      cabinetVersion: "", // 控制柜版本
      userName: "", // 姓名
      phoneNumber: "", // 电话
      company: "", // 公司
    };
    const data = reactive({
      page: 1,
      size: 10,
      total: 0,
    });
    const tableDataList = ref([]);
    const getData = async () => {
      try {
        const res = await request("getAllApplyList", {
          ...query,
          page: data.page,
          size: data.size,
        });
        console.log("getAllApplyList", res);
        tableDataList.value = res.records;
        data.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = (data) => {
      query = data
      getData()
    }
    const updatePage = (page, size) => {
        data.page = page || 1
        data.size = size || 10
        getData()
    }
    const handleDetail = async (data) => {
      try {
        console.log("handleDetail", data);
        await request("dealUserOtaApply", {
          id: data.id || "",
        });
        ElMessage({
          showClose: true,
          type: "success",
          message: "处理成功!",
        });
        getData();
      } catch (error) {
        console.error(error);
      }
    };
    getData();
    return {
      params,
      query,
      data,
      tableDataList,
      handleDetail,
      getData,
      searchData,
      updatePage
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
