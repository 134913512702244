<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button :disabled="!batchDataList.length" type="danger" @click="batchDelete">批量删除</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" tooltip-effect="light" select @select="handleSelect">
      <el-table-column prop="createTime" label="创建时间" width="150" />
      <el-table-column prop="userName" label="姓名" width="150" />
      <el-table-column prop="phoneNumber" label="联系电话" width="150" >
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="message" label="反馈意见" width="250">
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
          >
            <template #default>
              {{row.message}}
            </template>
            <template #reference>
              <span style="white-space: nowrap;">{{row.message}}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="attachments" label="附件" >
        <template
          #default="{row}"
        >
          <!-- <a v-for="(item, index) in (row.attachments || '').split(',')" :key="index" :href="item">附件{{index}}</a> -->
          <el-image
            v-for="(item, index) in (row.attachments?row.attachments.split(',').slice(0,3):[])" :key="index"
            class="el-image-cell"
            :src="item"
            :preview-src-list="(row.attachments || '').split(',')"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="tag" label="标签" width="150" />
      <el-table-column label="操作"  width="180">
        <template #default="{row}">
          <el-button type="primary" plain size="mini" @click="checkDetail(row)" 
            >查看详情</el-button
          >
          <el-button type="danger" plain size="mini" @click="deleteDetail(row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="意见反馈详情" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="form" class="dialog-form" :model="detailform" label-width="120px">
        <el-form-item label="创建时间：">
          <div>{{ detailform.createTime }}</div>
        </el-form-item>
        <el-form-item label="联系人：">
          <div>{{ detailform.userName }}</div>
        </el-form-item>
        <el-form-item label="联系电话：">
          <div>{{ detailform.phoneNumber }}</div>
        </el-form-item>
        <el-form-item label="故障详情：">
          <div class="form-textarea">
            {{ detailform.message }}
          </div>
        </el-form-item>
        <el-form-item label="故障附件：">
          <template
            v-for="(item, index) in (detailform.attachments || '').split(',')"
            :key="index"
          >
            <el-image
              v-if="item"
              class="el-image-inline"
              :src="item"
              :preview-src-list="(detailform.attachments || '').split(',')"
            >
            </el-image>
          </template>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      batchDataList: [],
      detailform: {
        createTime: '',
        userName: '',
        phoneNumber: '',
        message: '',
        attachments: '',
        tag: ''
      },
      params: [
        {
          key: "userName",
          value: "",
          label: "姓名",
        },
        {
          key: "phoneNumber",
          value: "",
          label: "电话",
        }
      ]
    })

    const getData = async () => {
      try {
        const res = await request("getFeedbackList", {
          ...state.query,
          page: state.page,
          size: state.size,
          status: 1
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data;
      getData()
    };
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    };
    const checkDetail = (row) => {
      state.dialogFormVisible=true
      state.detailform= row
    };
    const handleSelect = (data) => {
      state.batchDataList = data.map((item)=>{
        return { id: item.id}
      })
    }
    const deleteDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request('setFeedBackStatus', {
          id: row.id,
          status: 100
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const batchDelete = async () => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request('batchDeleteFeedBack', {
          batchList: state.batchDataList
        })
        state.batchDataList = []
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
      checkDetail,
      deleteDetail,
      batchDelete,
      handleSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;  
  display: flex;
  flex-direction: column;
}
.el-image-cell {
  width: 150px;
  height: 150px;
  margin: 10px 10px 0 0;
}
</style>